<template>

<div class="divide" :class="{'is-fill': fill}" />

</template>

<script>

export default {

	props: ['fill']

}

</script>

<style scoped>

.divide {
	height: 1px;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.1);
	margin: 10px 0px;
}

.divide.is-fill {
	flex-grow: 1;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	background-color: transparent;
}

</style>
